<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav v-if="userName" class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userName }} <feather-icon icon="ChevronDownIcon" size="21" />
            </p>
          </div>
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click.prevent="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Выйти</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ToastificationContent,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters([
      'auth/currentUser'
    ]),
    userName() {
      if (!this['auth/currentUser']) {
        return ''
      }

      const { first_name, last_name, username } = this['auth/currentUser']

      return first_name && last_name ? `${first_name} ${last_name}` : username
    },
  },
  methods: {
    ...mapActions([
      'auth/logout'
    ]),
    async logout() {
      try {
        await this['auth/logout']()

        this.$router.push({ name: 'login' })
      } catch(_) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    }
  },
}
</script>
