export default [
  {
    header: 'QR-Меню',
  },
  {
    title: 'Категории',
    route: 'categories',
    icon: 'TagIcon',
  },
  {
    title: 'Позиции',
    route: 'positions',
    icon: 'FileTextIcon',
  },
  {
    title: 'Настройки',
    route: 'settings',
    icon: 'SettingsIcon',
  },
]
